<template>
  <div class="category-show">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-6" v-for="cate in subCategories[0].childs" :key="cate.id">
          <a :href="'/'+$i18n.locale+'/'+cate.title+'/products'">
            <div class="product">
              <div class="image-wrapper">
                <img :src="'/img/categories/'+cate.logo" :alt="cate.logo" @error="$event.target.src='/img/default.png'" class="product-image">
                <div class="product-title"><h3>{{ cate.name }}</h3></div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpClient from '@/services/http.service';
export default {
  name:'CategoryShow',
  props: ['category'],
  data(){
    return {
      subCategories:new Array(),
      error:null,
    };
  },
  async mounted() {
    try {
      const {status , data} = await httpClient.get('/fa/categories',{params:{
        title:this.category,
        expand:'childs,'
      }})
      if (status == 200) {
        this.subCategories = data;
      }
    } catch (e) {
      this.errors = 'Conection Is Faild';
    }
  }
}
</script>

<style lang="css" scoped>
.product{
  background: #fff;
  margin-bottom:10px;
  text-align: center;
  position: relative;
}
.product-title{
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity:1;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}
.product-title:hover{
  color:rgba(40, 40, 110, 0.774);
}

.image-wrapper{
  width: 100%;
  overflow: hidden;
}
.product-image{
  display: block;
  width: 100%;
  height: auto;
  
}
a:hover{
  text-decoration: none;
}
.product-title h3{
  font-weight: bolder;
  font-size: 20px;
}
</style>
