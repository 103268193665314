<template>
    <div class="products">
        <navbar></navbar>
        <div class="empty"></div>
        <products-list :border="border" :category="category" v-if="parentCategory != null"></products-list>
        <category-show :category="category" v-else></category-show>
        <div class="empty"></div>
        <Footer />
    </div>
</template>

<script>
import Navbar from '../components/Navbar.vue';
import ProductsList from '../components/ProductsList.vue';
import CategoryShow from '../components/CategoryShow.vue';
import httpClient from '@/services/http.service';
import Footer from '../components/Footer.vue';
    export default {
    components: {ProductsList, Navbar,CategoryShow,Footer },
        name:'Products',
    data(){
        return{
            category:this.$route.params.category,
            parentCategory:null,
            border:'',
        }
    },
     async created(){
        await this.syncData();
        this.createBorder();
    },
    methods:{
          async createBorder(){
            if (this.$route.params.category == 'undefined') {
              this.category = 'plast';
            }else if(this.parentCategory == null){
              this.category = this.$route.params.category;
            }
            if (this.parentCategory == 'plast' || this.category=='plast') {
              this.border = 'border-plast';
              //this.childBorder = "has-child-plast";
            }else if(this.category == 'tajhiz' || this.parentCategory == 'tajhiz'){
              this.border = 'border-tajhiz';
              //this.childBorder = "has-child-tajhiz";
            }else{
              this.createBorder();
            }
          },
          async syncData(){
            try {
                const {status , data} = await httpClient.get('/fa/categories',{params:{
                  title:this.$route.params.category,
                  expand:'childs,'
                }})
                if (status == 200) {
                  if(data[0].parent != null){
                    this.parentCategory = data[0].parentCategory.title;
                  }else{
                    this.category = data[0].title;
                  }
                  
                }
              } catch (e) {
                this.errors = 'Conection Is Faild';
              }
          }
        }
    
    }
</script>

<style lang="scss">
body{
  overflow-y: visible;
}
.empty{
    height: 100px;
}
@media (max-width: 600px){
    .empty{
        height: 20px;
    }
}
</style>
